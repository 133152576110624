<template>
  <div class="px-4">
      <v-text-field
        v-model="layouts.data.currentPassword"
        label="Enter Current Password"
        :append-icon="currentPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
        :type="currentPasswordVisible ? 'text' : 'password'"
        @click:append="currentPasswordVisible = !currentPasswordVisible"
        required
      ></v-text-field>

      <v-text-field
        v-model="layouts.data.newPassword"
        :append-icon="newPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
        :type="newPasswordVisible ? 'text' : 'password'"
        @click:append="newPasswordVisible = !newPasswordVisible"
        label="Enter New Password"
        required
      ></v-text-field>

      <v-text-field
        v-model="layouts.data.confirmPassword"
        :append-icon="confirmPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
        :type="confirmPasswordVisible ? 'text' : 'password'"
        @click:append="confirmPasswordVisible = !confirmPasswordVisible"
        label="Confirm New Password"
        required
      ></v-text-field>

    <check-password-strength
      v-model="layouts.data.newPassword"
      :confirmPassword="layouts.data.confirmPassword"
      @strengthResult="updateStrengthResult"
    ></check-password-strength>
    <div class="pt-5 mt-5">
      <wap-btn
        :height="50"
        width="100%"
        :disabled-control="!(layouts.data.passwordStrength
        && layouts.data.newPassword === layouts.data.confirmPassword
        && assists.tool.isNotEmpty(layouts.data.newPassword)
        && assists.tool.isNotEmpty(layouts.data.confirmPassword))"
        @click="changePassword"
      >Change Password
      </wap-btn>
    </div>
  </div>
</template>

<script>
import WapBtn from '@/components/base/WapBtn'
import CheckPasswordStrength from '@/components/common/CheckPasswordStrength'
import { AccountApi } from '@/api'
import { Crypto, Cache, Tool } from '@/assets/js/util'

export default {
  name: 'ChangePassword',
  components: {
    WapBtn,
    CheckPasswordStrength
  },
  data () {
    return {
      currentPasswordVisible: false,
      newPasswordVisible: false,
      confirmPasswordVisible: false,
      assists: {
        cache: new Cache(),
        tool: new Tool()
      },
      layouts: {
        data: {
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
          passwordStrength: false
        }
      }
    }
  },
  methods: {
    changePassword () {
      const request = {
        oldPassword: Crypto.md5(this.layouts.data.currentPassword),
        newPassword: Crypto.md5(this.layouts.data.newPassword)
      }
      AccountApi.changePassword(
        request,
        () => {
          this.assists.tool.setPopupInformation('Password changed successfully.', 'success')
          setTimeout(() => {
            this.assists.cache.clear()
            this.$router.push('/sign-in')
          }, 2000)
        },
        (err) => this.assists.tool.setPopupInformation(err.message, 'error')
      )
    },
    updateStrengthResult (result) {
      this.layouts.data.passwordStrength = result
    }
  }
}
</script>

<style scoped>

</style>
